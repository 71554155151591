var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("auth-form", {
    attrs: {
      "is-reset": "",
      action: "Set a new password",
      route: "password.reset"
    },
    on: { success: _vm.success },
    scopedSlots: _vm._u([
      {
        key: "password-strength",
        fn: function(props) {
          return [
            _c(
              "p",
              { staticClass: "help" },
              [
                _c(
                  "password-strength",
                  _vm._b({}, "password-strength", props, false)
                )
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }